.App {
  text-align: center;
}
.mintContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.gif {
  width: 30vw;
  height: auto;
  margin-bottom: 2vh;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.55) 0px 15px 25px;
}
.MuiButton-label {
  font-size: 2.2vh !important;
  /* width: 6.7vw !important; */

  text-align: center !important;
  padding-left: 1vw !important;
  padding-right: 1vw !important;
}
html {
  background: url(bg.png);
  background-size: cover;
  background-attachment: fixed;
  background-position: center right;
}

@media screen and (max-width: 800px) {
  .gif {
    width: 60vw;
    height: auto;
  }
}
::placeholder{
  color: white !important;
}